import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { EventService } from 'src/services/event.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { getBuilderFromUserProperties, getFileExtension, getFileIcon } from '../../multi-use-helpers';
import { filterUserProperties } from '../../multi-use-helpers';
import { UploadDocumentModalComponent } from '../upload-document-modal/upload-document-modal.component';

@Component({
  selector: 'app-folder-documents',
  templateUrl: './folder-documents.component.html',
  styleUrls: ['./folder-documents.component.scss'],
})
export class FolderDocumentsComponent implements OnInit, OnChanges {
  user: any;
  userId: number;

  client: any;
  clientId: number;

  filterFailure = false;
  folderId: number;

  getFileIcon = getFileIcon;
  getFileExtension = getFileExtension;
  getBuilderFromUserProperties = getBuilderFromUserProperties;

  originalDocuments: any;

  sessionStorage = sessionStorage;

  @Input() folderName: string;
  @Input() documents: any;

  /*
    Documents array should have objects in this format:
    {
      id: number,
      title: string,
      can_edit: boolean, // whether the user can edit the document
      display_date: string, // Please format like this: "Dec 07, 2023"
      file_name: string,
      // optionally
      description: string
    }
  */

  @Input() documentClick?: any;
  // Action to be taken when a document is clicked, takes the document object as a parameter

  @Input() routeBackString?: string;
  // route to be taken when the file cabinet button is clicked/back button is clicked

  @Input() userType: any;
  // type of user used to determine modal display information
  // options are: 'customer', 'builder', 'hc',

  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  // emits an event to refresh the document data passed by the parent component

  properties: any[] = [];
  builderAccount: any;
  selectedProperty: any;
  error: boolean;
  errorMessage: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public modalService: NgbModal,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private eventService: EventService
  ) {}

  async ngOnInit() {
    this.user = await this.localStorageService.getUser();
    this.userId = this.user.id;

    this.client = await this.localStorageService.getSelectedCustomer();
    this.clientId = this.client.id;

    await this.getUserData();

    this.builderAccount = this.sessionStorage.isBuilderSession;

    this.originalDocuments = _.cloneDeep(this.documents);
    this.eventService.emitEvent('menuClicked');

    // Get Folder Id
    this.route.paramMap.subscribe((params) => {
      this.folderId = Number(params.get('folderId'));
    });

    // Prevent navigation to non existent folders
    const min_folder_id = 0;
    const max_folder_id = 9;
    if (this.folderId > max_folder_id || this.folderId < min_folder_id) {
      this.router.navigate(['/unauthorised']);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.documents) {
      for (const document of changes.documents.currentValue) {
        if (document.file_name) {
          document.file_name = decodeURIComponent(document.file_name).split('.');
          document.file_name.pop();
          document.file_name = document.file_name.join(' ');
        }
      }
      this.originalDocuments = _.cloneDeep(changes.documents.currentValue);
    }
  }

  async getUserData() {
    this.dataService.getUserDetails(this.clientId).subscribe((data: any) => {
      this.client = data;
      if (this.builderAccount) {
        const builderId = this.user.builder_accounts[0].id;
        if (builderId) {
          const filteredProperties = filterUserProperties(data.user_properties);
          for (const prop of filteredProperties) {
            if (prop.property.builder_account.id === builderId) {
              this.properties.push(prop);
            }
          }
          if (this.properties && this.properties.length > 0) {
            this.error = false;
          } else {
            this.error = true;
            this.errorMessage =
              'There was an error getting your client\'s property information. Please try again later.';
          }
        }
      } else {
        this.properties = filterUserProperties(data.user_properties);
      }
    });
  }

  // triggers analytics for file cabinet interaction
  triggerAnalytics(document) {
    const extension = this.getFileExtension(document.url);

    const builder = this.getBuilderFromUserProperties(document.user_property_id, this.user.user_properties);
    const builderName = builder ? builder.builder_name : '';

    window.analytics.track('File Opened', {
      user_id: this.userId,
      file_name: document.title,
      file_type: extension,
      file_url: document.url,
      timestamp: moment().format(),
      account_name: builderName,
      is_mobile_app: false,
    });
  }

  // Routes to the previous page
  onFileCabinetClick() {
    this.router.navigate([this.routeBackString]);
  }

  // prevents the default action of the event and deletes the file
  onDeleteClick(document, event) {
    event.preventDefault();
    event.stopPropagation();

    const options = {
      type: 'warning',
      title: 'Are you sure you want to archive this file?',
      confirmButtonText: 'Ok',
      showCancelButton: true,
    } as SweetAlertOptions;
    Swal(options).then((result) => {
      if (result.value) {
        // API call
        this.dataService.deleteDocument(document.id).subscribe(
          (data) => {
            Swal({
              type: 'success',
              title: 'Success!',
              text: 'File Archived',
              timer: 2000,
              showConfirmButton: false,
            });
            window.analytics.track('Engage File Cabinet', {
              user_id: this.userId,
              timestamp: moment().format(),
              file_deleted: document.file_name,
            });
            this.refreshData.emit();
          },
          (error) => {
            Swal({
              type: 'error',
              title: 'Error',
              text: 'There was an error archiving your file. Please try again later.',
              timer: 2000,
              showConfirmButton: false,
            });
          }
        );
      }
    });
  }

  // Triggers alaytics on document click and fires on click function
  onDocumentClick(document) {
    this.triggerAnalytics(document);
    if (this.documentClick) {
      this.documentClick(document);
    }
  }

  // Opens the upload document modal
  onDocumentAdd() {
    const modalRef = this.modalService.open(UploadDocumentModalComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.folderName = _.cloneDeep(this.folderName);
    modalRef.componentInstance.userType = _.cloneDeep(this.userType);
    modalRef.componentInstance.properties = _.cloneDeep(this.properties);

    if (this.userType !== 'customer') {
      modalRef.componentInstance.client = _.cloneDeep(this.client);
    }

    modalRef.result.then((result) => {
      this.refreshData.emit();
    });
  }

  // searches documents by title, file_name, and display date
  searchDocumentsByName(event) {
    this.documents = this.originalDocuments;
    const searchValue = event.target.value.toLowerCase().trim(); // normalize search value
    const searchDocuments = [];

    // if search value is not empty
    if (searchValue && searchValue !== '') {
      for (const document of this.documents) {
        // searches title first and pushed to array if it containes the search value
        let pushed = false;
        if (!pushed && document.title) {
          if (document.title.toLowerCase().includes(searchValue)) {
            searchDocuments.push(document);
            pushed = true;
          }
        }

        // if object is already pushed don't search file_name otherwise search the file_name
        if (!pushed && document.file_name) {
          if (document.file_name.toLowerCase().includes(searchValue)) {
            searchDocuments.push(document);
            pushed = true;
          }
        }
        // if object is already pushed don't search display_date otherwise search the display_date
        if (!pushed && document.display_date) {
          if (document.display_date.toLowerCase().includes(searchValue)) {
            searchDocuments.push(document);
            pushed = true;
          }
        }
      }

      if (searchDocuments.length !== 0) {
        // found documents containing search value
        this.filterFailure = false;
        this.documents = searchDocuments;
      } else {
        // no documents found containing search value
        this.filterFailure = true;
      }
    } else {
      // empty search value
      this.filterFailure = false;
      this.documents = this.originalDocuments;
    }
  }
}

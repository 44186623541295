export const environment = {
  production: false,
  demo: false,
  local: false,
  staging: false,
  rest_api_server: '/api/v1',
  rest_api_server_v2: '/api/v2',
  fallbackUrl: '/',
  helloSignApi: '<HELLO_SIGN_API>',
};

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { FileUploadService } from 'src/app/_helpers/file-upload.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import Utils from 'src/app/AppPages/Elements/utils';
import { ThemeOptions } from 'src/app/theme-options';
import { EventService } from 'src/services/event.service';
import { SharedService } from 'src/services/shared.service';
import { urlify } from '../../../AppPages/multi-use-components/multi-use-helpers';

@Component({
  selector: 'app-chat-widget',
  templateUrl: './chat-widget.component.html',
  styleUrls: ['./chat-widget.component.scss'],
  animations: [
    trigger('heightAnimationMobile', [
      state('shrink', style({ height: '0px' })),
      state('expand', style({ height: 'calc(100vh - 140px)' })),
      transition('shrink <=> expand', animate('300ms ease-in-out')),
    ]),
    trigger('heightAnimationDesktop', [
      state('shrink', style({ height: '0px' })),
      state('expand', style({ height: 'calc(100vh - 325px)' })),
      transition('shrink <=> expand', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ChatWidgetComponent implements OnInit {
  chatWindowExpandFlag = false;
  profileScheduleFlag = false;
  profileButtonFlag = false;
  serviceId: any;
  askConnieBody: any = {};
  unreadCount: any;
  isMobile = false;
  videoURL: any;

  @Input()
  user: any;
  messages: any = [];
  currentUser: any;
  attachments: any = [];
  isNotification = false;
  commentDocuments: any = [];
  commentText: any = '';
  commentList: any = [];
  is_customer = false;
  HC_details: any = {};
  browserType: any;
  userDetails: any;
  urlify = urlify;
  currentMountainTime: any;
  isChristmas = false;
  isNewYear = false;
  isDuringOfficeHours = true;
  isWeekend = false;

  constructor(
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    private fileUploadService: FileUploadService,
    public globals: ThemeOptions,
    private eventService: EventService,
    private router: Router,
    private sharedService: SharedService,
    private DomSanitizer: DomSanitizer,
    @ViewChild('scrollMe') private myScrollContainer: ElementRef
  ) {
    this.checkScreenWidth();
    this.currentMountainTime = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'America/Denver' })
    );
    this.isDuringOfficeHours = this.isWithinOfficeHours();
    this.isChristmas = this.isChristmasDay();
    this.isNewYear = this.isNewYearDay();
    this.isWeekend = this.isWeekendTime();
  }

  async ngOnInit() {
    this.currentUser = await this.localStorageService.getUser();
    await this.getUserDetails(this.currentUser.id);
    if (
      this.currentUser.roles.includes('customer_user') ||
      this.currentUser.roles.includes('customer_user_additional')
    ) {
      this.is_customer = true;
    }
    this.eventService.myEvent$.subscribe((event) => {
      this.askConnieBody = event;

      if (this.askConnieBody === 'menuClicked') {
        if (this.globals.isFirstLogin == false) {
          this.getUserDetails(this.currentUser.id);
          this.scrollToBottom();
        }
        if (this.chatWindowExpandFlag) {
          this.loadMessages();
        } else {
          this.loadMessageBoardCount();
        }
      } else {
        this.chatWindowExpandFlag = true;
        this.loadMessages();
        this.scrollToBottom();
        this.markCommentsAsRead();
        this.getUserDetails(this.currentUser.id);
      }
    });

    this.loadMessages();
    this.scrollToBottom();
  }

  isWithinOfficeHours(): boolean {
    if (!this.currentMountainTime) {
      return false; // Handle cases where time isn't initialized
    }

    const hour = this.currentMountainTime.getHours();
    return hour >= 9 && hour < 17; // Office hours: 9 AM to 5 PM
  }

  isChristmasDay(): boolean {
    if (!this.currentMountainTime) {
      return false; // Handle cases where time isn't initialized
    }

    const month = this.currentMountainTime.getMonth(); // Months are 0-indexed in JavaScript
    const day = this.currentMountainTime.getDate();

    // December 25th or 26th
    return month === 11 && (day === 25 || day === 26);
  }

  isNewYearDay(): boolean {
    if (!this.currentMountainTime) {
      return false; // Handle cases where time isn't initialized
    }

    const month = this.currentMountainTime.getMonth();
    const day = this.currentMountainTime.getDate();

    // January 1st
    return month === 0 && day === 1;
  }

  isWeekendTime(): boolean {
    if (!this.currentMountainTime) {
      return false; // Handle cases where time isn't initialized
    }

    const day = this.currentMountainTime.getDay(); // 0 is Sunday, 6 is Saturday
    return day === 0 || day === 6;
  }

  getUserDetails(id) {
    this.dataService.getUserDetails(id).subscribe((data: any) => {
      this.userDetails = data;
      if (data.hc.video_url) {
        this.videoURL = this.formatURL(data.hc.video_url);
      } else {
        this.videoURL = null;
      }
    });
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  toggleChatWindow() {
    this.chatWindowExpandFlag = !this.chatWindowExpandFlag;
    this.getUserDetails(this.currentUser.id);
    this.loadMessages();
    this.scrollToBottom();
    if (this.chatWindowExpandFlag === true && this.unreadCount > 0) {
      this.markCommentsAsRead();
    }
    if (this.chatWindowExpandFlag === true) {
      const currentURL = window.location.href;
      window.analytics.track('Engage Concierge', {
        user_id: this.currentUser.id,
        timestamp: moment().format(),
        page_name: currentURL,
      });
    }
  }

  loadMessageBoardCount() {
    this.dataService
      .loadMessageBoardCount(this.currentUser.message_board.id)
      .subscribe((data: any) => {
        this.unreadCount = data.unread_count;
      });
  }

  toggleProfileSchedule() {
    this.profileScheduleFlag = !this.profileScheduleFlag;
  }

  toggleProfile() {
    this.profileButtonFlag = !this.profileButtonFlag;
  }

  onScheduleClick() {
    window.analytics.track('Select Schedule', {
      user_id: this.currentUser.id,
      timestamp: moment().format(),
    });
  }

  markCommentsAsRead() {
    const body = {
      board_id: this.currentUser.message_board.id,
    };
    this.dataService.markCommentsAsRead(body).subscribe((data: any) => {
      this.messages = data.comments;
      this.attachments = data.attachments;
      this.unreadCount = 0;
    });
  }

  onPaste(e: any) {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    let blob = null;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        blob = item.getAsFile();
        this.commentDocuments.push(blob);
      }
    }
  }

  showURL(url) {
    window.open(url, '_blank');
  }

  isToday(due_date) {
    if (due_date) {
      return Utils.isTodoDueToday(due_date);
    } else {
      return false;
    }
  }

  isTodoOverDue(due_date) {
    if (due_date) {
      return Utils.isTodoOverDue(due_date);
    } else {
      return false;
    }
  }

  loadMessages() {
    this.dataService
      .loadMessageBoardComments(this.currentUser.message_board.id)
      .subscribe((data: any) => {
        this.messages = data.comments;
        this.attachments = data.attachments;
        this.isNotification = data.is_notification;
        this.unreadCount = data.unread_count;
        if (this.chatWindowExpandFlag) {
          this.markCommentsAsRead();
        }
      });
  }

  shouldAlignEnd(comment: any): boolean {
    // Your condition for aligning to flex-end
    if (
      this.currentUser.id === comment.user_id &&
      comment.is_document === false &&
      comment.user_id !== null &&
      comment.task_id === null &&
      comment.comment_by === 'Client'
    ) {
      return true;
    } else {
      return false;
    }
  }

  processedSendTimeText(value: string): string {
    return value.replace(', 2023', '');
  }

  additonalUserInitials(name: string): string {
    if (name) {
      const words = name.split(' ');
      const firstTwoWords = words.slice(0, 2);
      const initials = firstTwoWords.map((word) =>
        word.charAt(0).toUpperCase()
      );
      return initials.join('');
    }
  }

  onChangeSwitch() {
    this.dataService
      .onChangeChatToggleButton(this.currentUser.message_board.id)
      .subscribe((data: any) => {});
  }

  uploadCommentFileUrl(doc, id) {
    this.fileUploadService.uploadFile(
      doc.name,
      doc,
      'MESSAGE_DOCUMENT',
      id,
      doc.size
    );
  }

  uploadDocument(id) {
    const element = document.getElementById(id);
    element.click();
  }

  removeFile(index) {
    this.commentDocuments.splice(index, 1);
    if (this.commentDocuments.length === 0) {
      this.commentDocuments = [];
      const fileInput = document.getElementById(
        'upload_to_comment'
      ) as HTMLInputElement;
      fileInput.value = ''; // Reset the input value
    }
  }

  commentFileChangeEvent(event: any): void {
    if (event.target.files.length > 0) {
      for (const i of event.target.files) {
        this.commentDocuments.push(i);
      }
    }
  }

  sendMessage() {
    // get current URL
    const currentURL = window.location.href;
    let task_id = null;
    let is_service_task = null;
    if (currentURL.includes('quotes-matches')) {
      // get task id from current URL
      const urlArray = currentURL.split('/');
      task_id = urlArray[urlArray.length - 2];
      if (task_id && this.commentText !== '') {
        this.dataService
          .getMatchesById(task_id, false)
          .subscribe((data: any) => {
            is_service_task = data.is_service_task;

            if (is_service_task == true) {
              const body = {
                comment: this.commentText,
                is_document: false,
                board_id: this.currentUser.message_board.id,
                task_id,
              };
              this.dataService
                .addMessagesComment(body)
                .subscribe((data: any) => {
                  this.loadMessages();
                });
              this.commentText = '';
            } else {
              const body = {
                comment: this.commentText,
                is_document: false,
                board_id: this.currentUser.message_board.id,
              };
              this.dataService
                .addMessagesComment(body)
                .subscribe((data: any) => {
                  this.loadMessages();
                });
              this.commentText = '';
            }
          });
      }
    } else {
      if (this.commentText !== '') {
        const body = {
          comment: this.commentText,
          is_document: false,
          board_id: this.currentUser.message_board.id,
        };
        this.dataService.addMessagesComment(body).subscribe((data: any) => {
          this.loadMessages();
        });
        this.commentText = '';
      }
    }

    if (this.commentDocuments && this.commentDocuments.length > 0) {
      for (const i of this.commentDocuments) {
        const body = {
          comment: i.name,
          is_document: true,
          board_id: this.currentUser.message_board.id,
        };

        this.dataService.addMessagesComment(body).subscribe((data: any) => {
          this.fileUploadService.uploadFile(
            i.name,
            i,
            'MESSAGE_DOCUMENT',
            data.latest_comment.id,
            i.size
          );
        });
      }
    }

    const that = this;
    setTimeout(() => {
      that.loadMessages();
      that.commentList = [];
      that.commentDocuments = [];
      const fileInput = document.getElementById(
        'upload_to_comment'
      ) as HTMLInputElement;
      fileInput.value = '';
    }, 1000);
  }

  goToQuotesMatches(taskId: any) {
    const category_name = 'chat';
    this.router.navigate([
      '/customer/' +
        this.currentUser.id +
        '/quotes-matches/' +
        taskId +
        '/' +
        category_name,
    ]);
    this.checkURL().then((isUrlDifferent) => {
      if (!isUrlDifferent) {
        window.location.reload();
      }
    });
  }

  checkURL(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const currentURL = window.location.href;
      this.sharedService.currentURL$.subscribe((url) => {
        if (currentURL === url) {
          // URLs are the same
          resolve(false);
        } else {
          // URLs are different
          resolve(true);
        }
      });
    });
  }

  checkScreenWidth() {
    this.isMobile = window.innerWidth <= 450;
  }

  formatURL(url) {
    let formattedURL: any;
    if (url && url.includes('shorts')) {
      const replacedString = url.replace(/\/shorts\//, '/embed/');
      formattedURL = replacedString + '?rel=0';
    } else {
      formattedURL = url;
    }

    const sanitizedURL =
      this.DomSanitizer.bypassSecurityTrustResourceUrl(formattedURL);

    return sanitizedURL;
  }
}

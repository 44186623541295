import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { DeviceDetectorService } from 'src/services/device-detactor.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private localStorageService: LocalStorageService,
    public router: Router,
    private deviceDetectorService: DeviceDetectorService
  ) {
    const deviceOS = this.deviceDetectorService.getDeviceOS();
    if (deviceOS === 'iOS' || deviceOS === 'Android') {
      this.openMobileApp(deviceOS);
    }
  }

  openMobileApp(deviceOS: string) {
    const iosAppUrl =
      'https://apps.apple.com/us/app/virtuo-love-where-you-live/id1529864611';
    const androidAppUrl =
      'https://play.google.com/store/apps/details?id=com.virtuo.appv2';
    const isChrome = /CriOS/.test(navigator.userAgent); // Check if the browser is Chrome on iOS
    let hasRedirected = false; // Flag to avoid multiple redirections

    if (deviceOS === 'iOS') {
      if (isChrome) {
        // Chrome on iOS
        window.location.href = iosAppUrl;
      }
    }

    if (deviceOS === 'Android') {
      const host = window.location.host;
      const path = window.location.pathname;
      const extractedUrl = `${host}${path}`;

      // Construct the deep link with the extracted URL
      const appDeepLink = `intent://${extractedUrl}#Intent;scheme=virtuoapp;package=com.virtuo.appv2;end`;

      // Attempt to open the app directly
      window.location.href = appDeepLink;

      // Set a timeout to check if the app opened
      const timeout = setTimeout(() => {
        if (!hasRedirected) {
          hasRedirected = true; // Ensure this runs only once
          // Uncomment the next line if you want to redirect to fallback
        }
      }, 2000); // 2-second delay

      // Clean up the timeout once app opens or user navigates away
      window.addEventListener('pagehide', () => {
        clearTimeout(timeout);
      });
    }
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const user = this.localStorageService.getUser();

    if (!user || !user.authentication_token) {
      this.router.navigate(['login']);
      return false;
    }

    // Check for the 'User' builder_role only for specific routes
    const restrictedRoutes = [
      'campaigns',
      'campaigns/create-campaign',
      'campaigns/edit-campaign/:id',
      'clients/new-client/:id',
      'builder-referrals',
      'builder-customer-care',
      'community-content',
      'community-add',
      'builder-users',
      'new-broadcast',
      'new-broadcast/:id',
      'broadcast-list',
    ];

    if (
      route.routeConfig &&
      restrictedRoutes.includes(route.routeConfig.path) &&
      user.builder_role === 'User'
    ) {
      // Redirect to an unauthorized page or handle access denied as needed
      this.router.navigate(['/unauthorised']);
      return false;
    }

    if (!environment.staging) {
      const onlyForStaging = [
        'customer/:userId/rewards',
        'gm/settings/reward-configuration',
        'gm/settings/rewards-manager',
        'gm/settings/rewards-manager/rewards-detail/:reward_claim_id',
        'gm/settings/rewards-manager/rewards-create',
      ];

      if (
        route.routeConfig &&
        onlyForStaging.includes(route.routeConfig.path) &&
        !environment.staging
      ) {
        // Redirect to an unauthorized page or handle access denied as needed
        this.router.navigate(['/unauthorised']);
        return false;
      }
    }

    const expectedRoles = route.data.expectedRoles as string[];

    // Check if the user has any of the expected roles
    if (this.checkRoles(user.roles, expectedRoles)) {
      return true;
    } else {
      // Redirect to an unauthorized page or handle access denied as needed

      this.router.navigate(['/unauthorised']);
      return false;
    }
  }

  private checkRoles(userRoles: string[], expectedRoles: string[]): boolean {
    if (expectedRoles === undefined || expectedRoles.length === 0) {
      return true;
    } else {
      return userRoles.some((role) => expectedRoles.includes(role));
    }
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ThemeOptions {
  sidebarHover = false;
  toggleSidebar = false;
  toggleSidebarMobile = false;
  toggleHeaderMobile = false;
  toggleThemeOptions = false;
  toggleDrawer = false;
  toggleFixedFooter = false;
  isGMActive = false;
  isAdminActive = false;
  _opened = false;
  isCustomerActive = false;
  isBuilderActive = false;
  jobsListDropdownValueSPN = '';
  baseUrl = window.origin + '/';
  helloSignApi = environment.helloSignApi; // demo
  isSidebarToggleFlag = false;
  isNotFoundPage = false;
  taskPageBreadcrumb = '';
  previousTaskId: any;
  isFirstLogin = true;

  constructor() {
    this.jobsListDropdownValueSPN =
      localStorage.getItem('jobsListDropdownValueSPN') || '';
  }
}
